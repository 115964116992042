<template>
  <div class="">
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <Layaut subtitle="Todos los usuarios">
      <v-row justify="end">
        <v-btn style="border-radius: 15px" color="primary" elevation="0" @click="addUser"
          class="ma-2 white--text elevation-animations">
          <v-icon right dark class="mr-3">fas fa-plus</v-icon>
          Crear nuevo usuario
        </v-btn>
      </v-row>
    </Layaut>
    <div class="body-template">
      <v-data-table :headers="headers" :items="allUsers" sort-by="createdAt" fixed-header :search="$store.state.search"
        :calculate-widths="true" :loading="loading" sort-desc item-key="id" :footer-props="{
      itemsPerPageOptions: [10, 25, 50, 100, 250],
    }">
        <template v-slot:[`item.active`]="{ item }">
          <v-switch @change="switchControlChanged({ active: item.active }, item)" v-model="item.active"
            :loading="loading"></v-switch>
        </template>
        <template v-slot:[`item.type`]="{ item }">
      {{ item.type | typeUser }}
        </template>

        <template v-slot:loading>
          <div class="ma-5" style="position: relative">
            <div class="lottie-wrapper">
              <v-progress-circular :width="3" color="#00cdbc" indeterminate></v-progress-circular>
              <p :class="$vuetify.theme.dark
        ? 'subtitle-1 primary--text'
        : 'subtitle-1 black--text'
      ">
                Cargando Data
              </p>
            </div>
          </div>
        </template>
        <template v-slot:[`item.options`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn @click="editUser(item)" v-on="on" small color="primary" >
                <v-icon  class="mr-2" dark small> fas fa-edit </v-icon> editar
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="addDialog" width="600" scrollable persistent v-if="addDialog" style="border-radius: 15px">
      <new-user :item="selectedItem" @cancel="addDialog = false" @success="handleSuccess"></new-user>
    </v-dialog>
  </div>
</template>

<script>
// import store from "@/store/index.js";
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import Layaut from "../../components/header-layaut.vue";
import moment from "moment-timezone";
import "moment/locale/es";
import newUser from "@/components/add-special-user";

moment.locale("es");
export default {
  name: "Users",
  components: {
    Layaut,
    newUser,
  },
  data() {
    return {
      addDialog: false,
      newItem: {},
      show1: false,
      loading: true,
      allUsers: [],
      snackbarText: "",
      snackbar: false,
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Correo",
          align: "start",
          sortable: true,
          value: "email",
        },
        {
          text: "Tipo",
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: "Activo",
          align: "start",
          sortable: true,
          value: "active",
        },
        {
          text: "",
          value: "options",
        },
      ],

      defaulImage: require("@/assets/templateImage.svg"),
    };
  },
  filters: {
    formatDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("lll A");
    },
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("es-HN", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
    typeUser(e){
  var roles = {
  viveSudo  : 'Super Usuario',
  sudo  : 'Super Usuario',
  viveOperations  : 'Operaciones',
  viveLesserAdmin  : 'Administrador',
  viveFinance  : 'Finanzas',
  viveSales  : 'Ventas',

 } 
 return roles[e] 
}
  },

  computed: {
    ...mapState(["user", "selectedBusiness"]),

    idBussines() {
      return this.selectedBusiness[`.key`];
    },

    headerTable() {
      if (this.user.type == "oplAdmin") {
        return this.headersSudo;
      }
      if (this.user.type == "oplBusiness") {
        return this.headers;
      }
    },
  },

  methods: {
    ...mapActions(["_searchTerm", "Alert_", "visibleSearch"]),

    editUser(item) {
      this.selectedItem = item;
      this.addDialog = true;
    },

    handleSuccess() {
      this.Alert_({
        text: `El usuario fue ${this.selectedItem ? "actualizado" : "creado"
          } con éxito`,
        timeout: 2000,
        btn_closed: true,
        icon: false,
        iconType: "mdi-check",
        type: "success",
      });
      this.addDialog = false;
      this.selectedItem = null;
    },

    addUser() {
      this.selectedItem = null;
      this.addDialog = true;
    },

    switchControlChanged(data, item) {
      if (data && item) {
        this.loading = true;
        data.modifiedAt = new Date();

        db.collection("appUsers")
          .doc(item.id)
          .update(data)
          .then(() => {
            this.loading = false;
            this.snackbarText = `El usuario ahora se escuentra ${data.active ? "activo" : "desactivado."
              }`;
            this.snackbar = true;
          })
          .catch(() => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            item.active = !item.active;
            this.loading = false;
          });
      }
    },

    async GetallUser() {
      this.tagsToSearchSection = "";
      this.$store.state.search = "";

      await this.$binding("allUsers",
        db.collection("appUsers")
          .where("type", "in", ["viveAdmin", "viveSales", "viveFinance", "viveLesserAdmin", "viveOperations", "viveSudo"])
          .where("deleted", "==", false)
      )
      this.loading = false
    },
  },
  async mounted() {
    this._searchTerm("usuarios");
    this.visibleSearch(true);

    if (['viveSudo', 'sudo'].includes(this.user.type)) {
      this.GetallUser();
    }
  },
};
</script>
<style lang="scss">
.dashboard {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  flex-direction: column;

  .wrap-login {
    width: 450px;
    height: 400px;
  
    border-radius: 1.5em !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
  }
}

.btn-primary-ocho {
  border-radius: 15px;
  box-shadow: 0px 4px 10px 5px rgba(255, 89, 0, 0.2);
}

.body-template {
  width: 95%;
  height: 500px;
}

.type-metod-img {
  border-radius: 10px;
}

.v-menu__content {
  border-radius: 15px !important;
}
</style>