<template>
    <v-card style="border-radius: 15px">
      <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
        snackbarText
      }}</v-snackbar>
      <v-card-title class="text-h5 lighten-2">
        {{ item ? "Editar usuario" : "Agregar usuario" }}
      </v-card-title>
  
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row style="margin: 0px">
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="newItem.name"
                :rules="required"
                label="Nombre del Usuario"
                style="border-radius: 15px"
                required
                hide-details
                filled
                rounded
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="newItem.email"
                :rules="required"
                label="Correo"
                style="border-radius: 15px"
                required
                hide-details
                filled
                rounded
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="newItem.password"
                :rules="item ? [] : required"
                label="Contraseña"
                style="border-radius: 15px"
                required
                hide-details
                filled
                rounded
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-select
                placeholder="Rol"
                v-model="newItem.type"
                :item-value="[`type`]"
                :rules="required"
                :items="roles"
                item-text="Roles"
                style="border-radius: 15px"
                required
                hide-details
                filled
                rounded
              >
                <template v-slot:selection="{ item }">
                  <span>{{ item.name }}</span>
                </template>
                <template v-slot:item="{ item }">
                  {{ item.name }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-switch v-model="newItem.active" label="Activo"> </v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
  
      <v-divider></v-divider>
  
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          style="border-radius: 15px"
          @click="$emit('cancel')"
          :loading="loadbtn"
        >
          Cancelar
        </v-btn>
        <v-btn
          style="border-radius: 15px"
          color="primary"
          :loading="loadbtn"
          @click="addNewUser()"
        >
          aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  import { db, fb } from "@/firebase";
  import moment from "moment-timezone";
  import "moment/locale/es";
  moment.locale("es");
  import { mapActions, mapState } from "vuex";
  
  export default {
    name: "new-link",
    props: ["item"],
    //   components: {
    //     // lottie: Lottie,
    //   },
    data() {
      return {
        valid: true,
        snackbar: false,
        snackbarText: "",
        defaulImage: require("@/assets/templateImage.svg"),
        loading: true,
        required: [(v) => !!v || "Name is required"],
        loadbtn: false,
       
        roles: [
          {
            type: "viveSudo",
            name: "Super Usuario",
          },
          {
            type: "viveOperations",
            name: "Operaciones",
          },
          {
            type: "viveLesserAdmin",
            name: "Administrador",
          },
          {
            type: "viveFinance",
            name: "Finanzas",
          },
          {
            type: "viveSales",
            name: "Ventas",
          },
        ],
        newItem: {
          name: "",
          email: "",
          password: "",
          active: true,
        },
      };
    },
    methods: {
      ...mapActions(["Alert_"]),
      async generateLink() {
        let validation = await this.validate();
  
        if (!validation) {
          return;
        }
  
        this.loading = true;
        var httpPaymentLinksGenerateManualPayment = fb
          .functions()
          .httpsCallable("httpPaymentLinksGenerateManualPayment");
        httpPaymentLinksGenerateManualPayment(this.link)
          .then((result) => {
            this.$emit("success");
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      },
  
      addNewUser() {
        this.loadbtn = true;
        this.$refs.form.validate();
        if (this.$refs.form.validate()) {
          var userResult = fb
            .functions()
            .httpsCallable(
              this.item ? "httpDiloUpdateUser" : "httpDiloAddUser"
            );
  
          userResult({
            name: this.newItem.name,
            email: this.newItem.email,
            password: this.newItem.password,
            active: this.newItem.active,
            type: this.newItem.type,
            uid: this.item ? this.item[".key"] : null,
          })
            .then((result) => {
              this.$emit("success");
            })
            .catch((err) => {
              // console.debug(err);
              this.Alert_({
                text: "Error al crear el usuario",
                timeout: 2000,
                btn_closed: true,
                icon: false,
                iconType: "mdi-check",
                type: "error",
              });
              this.loadbtn = false;
              // this.createUser = false;
            });
        } else {
          this.loadbtn = false;
          this.createUser = false;
          this.Alert_({
            text: "Rellena todos los campos seleccionados",
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "mdi-check",
            type: "error",
          });
        }
      },
  
      isNumber: function (evt) {
        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
  
      validate() {
        return new Promise((resolve, reject) => {
          if (!this.link.customer) {
            this.snackbarText = "Ingrese el nombre del cliente";
            this.snackbar = true;
            return resolve(false);
          }
  
          if (
            !this.link.amount ||
            isNaN(this.link.amount) ||
            this.link.amount <= 0
          ) {
            this.snackbarText = "Ingrese un monto a cobrar válido.";
            this.snackbar = true;
            return resolve(false);
          }
  
          if (!this.link.description) {
            this.snackbarText = "Ingrese una descripción del cobro";
            this.snackbar = true;
            return resolve(false);
          }
  
          return resolve(true);
        });
      },
    },
    async mounted() {
      this.loading = false;
  
      if (this.item) {
        this.newItem = Object.assign({}, this.item);
      }
    },
  };
  </script>